*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Cairo', sans-serif;
  /* transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
  -ms-transition: all 0.5s ease-in; */
}

.hidden{ display: none !important;}

.text-center{ text-align: center;}

.overflowXScroll{ overflow-x: auto;}

/* equivalent to dir="auto" */
input,textarea{unicode-bidi: plaintext}  

/* .boxForm .MuiTextField-root label{ */
.MuiTextField-root label{
  left: unset;
  right: 28px;
  transform-origin: top right;
} 

.MuiAutocomplete-root > div:first-of-type > label.MuiInputLabel-outlined
{
  right: 44px;
}
.MuiAutocomplete-root > div:first-of-type > label.MuiInputLabel-shrink
{
  right: 28px;
}

/* .boxForm .MuiTextField-root legend{ */
.MuiTextField-root legend{
  text-align: right;
}

.MuiFormHelperText-root{
  text-align: right !important;
}
/* start loading btn */
.loadingBtn, .loadingSmallBtn{
  padding-right: 0 !important;
}

.loadingBtn .MuiLoadingButton-loadingIndicator {
  left: 10px !important;
}

.loadingSmallBtn .MuiLoadingButton-loadingIndicator {
  right: 10px !important;
}

.btnLogo{
  margin-left: 8px;
  margin-top: 2px
}
/* end loading btn */

.appAlert .MuiAlert-icon{
  margin-right: 0px;
  padding: 12px 0px 7px 7px;
}

.appTable .MuiTablePagination-toolbar{
  direction: ltr;
}

.appTable .MuiTablePagination-displayedRows{
  direction: rtl;
}

.appTable .MuiTablePagination-root > .MuiToolbar-root{
  overflow-x: auto;
}

.MuiTooltip-tooltip{
  font-size: 0.7475rem !important;
}

.outlineForm .MuiInputLabel-root{
  left: unset !important;
  right: 22px !important;
  transform-origin: top right !important;
} 

.MuiInputBase-root.Mui-focused{
  right: 0 !important;
}

.appSortList .MuiInputBase-root:hover, .appSortList .MuiInputBase-root::before, .appSortList .MuiInputBase-root.Mui-focused{
  border-bottom: unset !important;
}

.appSortList .MuiInputBase-root{text-align: right !important;}

.appSearchBox{
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: 16px 0;
  border: 1px solid #949494;
  border-radius: 8px;
  text-align: right;
  overflow: hidden;
}

@media screen and (max-width: 425px){
  .appSearchBox{
    display: flex;
    flex-direction: column;
    min-width: 150px;
  }

  .appSearchBox .MuiFormControl-root{
    width: 100%;
  }
}

.MuiTablePagination-root{
  border-top: 2px solid #f0f0f0;
}


/* start imgModal */
.imgModal .MuiDialogContent-root{
  width: 525px;
  max-width: 525px;
}

.imgModal .css-1f8sh1y{
  height: 300px !important;
}

.imgModal .smallImage{
  width: 300px !important;
  height: 300px !important;
  object-fit: contain;
  background: #ffffff;
}

.imgModal .css-1l7c0cy>div:first-of-type>div:first-of-type{
  transform: none !important;
}

.imgModal .css-1m9128y{ direction: ltr }
.imgModal .css-1m9128y{margin-top: 25px !important;}
.imgModal .__react_modal_image__header{
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
/* end imgModal */

.outlinedSelect .MuiSelect-outlined, .outlinedSelect legend {
  text-align: right !important;
}

.outlinedSelect .MuiInputLabel-outlined{
  left: unset !important;
  right: -2px !important;
  padding-right: 42px !important;
  transform-origin: top right !important;
}

.outlinedSelect .MuiInputLabel-outlined.Mui-focused{
  right: -2px !important;
}

/* start google maps */
.mapsSearchLi{
  padding: 5px 10px;
  cursor: pointer;
  text-align: left;
}

.mapsSearchLi:hover, .inputSearchResultItem:hover{
  background-color: rgba(0, 0, 0, 0.1);
}
/* end google maps */

.mapModal{
  width: 500px;
  max-width: 500px
}

@media screen and (max-width: 425px){
  .mapModal{
    width: 100%;
    max-width: 100%
  }
}

/* start dropZone-container */
.dropZone-container .MuiTypography-h5{ font-size: 1rem;}
.dropZone-container .MuiBox-root{ padding: 2px 10px}
.dropZone-container .MuiSvgIcon-root{ width: 36px;}
.dropZone-container .MuiSvgIcon-root, .dropZone-container .MuiTypography-h5{color: rgb(133,133,133);}
/* end dropZone-container */


/* start search input */
.inputSearchContainer{
  width: 100%;
  position: relative
}

.inputSearchResultsContainer{
  position: absolute;
  width: 100%;
  min-height: 40px;
  background: white;
  z-index: 2;
  top: 54px;
  border: 1px solid #bdbdbd;
  border-radius: 0 0 5px 5px !important
}

.inputSearchResultItem{
  padding: 5px;
  /* background: red; */
  width: 100%;
  min-height: 40px;
  text-align: center;
  cursor: pointer
}
/* end search input */

/* start DatePicker */
.MuiMultiSectionDigitalClock-root, .MuiPickersLayout-contentWrapper > div:first-of-type{
  flex-direction: row-reverse !important;
}

.MuiPickersArrowSwitcher-root button.MuiIconButton-edgeStart,
.MuiPickersArrowSwitcher-root button.MuiIconButton-edgeEnd{
  transform: rotateZ(180deg);
}
/* end DatePicker */

.checkBox span.MuiFormControlLabel-label {
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.checkBox > div:first-of-type:not(.permissionsTitle){
  width: 130px;
  max-width: 130px;
}